export function makeInstrumentWithConfig(instrument, defaultInstrumentConfig, args) {
  /**
   * Makes a wrapper such that an instrument (for example monitorStates) can either be declared like this:
   * `myValue: monitorStates`
   * or like this:
   * `myValue: monitorStates({ ignore: ['foo'] })`
   */
  let instrumentConfig
  if (args.length <= 1) {
    instrumentConfig = args[0]
    return function(component, componentKey, componentConfig) {
      return instrument(component, componentKey, { ...componentConfig, ...defaultInstrumentConfig, ...instrumentConfig })
    }
  } else {
    const [component, componentKey, componentConfig] = args
    return instrument(component, componentKey, { ...componentConfig, ...defaultInstrumentConfig })
  }
}
