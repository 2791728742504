/**
 * Opportunity Atlas Filter Store
 * All filters for Opportunity Atlas are stored here
*/
import { defineStore, storeToRefs } from 'pinia';
import { ref, reactive, computed, watch } from 'vue';
import { FeatureMiningQueryAction } from '@/interfaces/opportunityAtlas';
import { useTriggersListStore } from '@/modules/triggers/store/triggersList';
import { useExploreTriggersStore } from '@/modules/triggers/store/useExploreTriggersStore';
import { INVENTIVENESS_SCORES } from '@/modules/projects/utils/constants';
import { CLUSTER_SIZE_CHOICES, DEFAULT_ZOOM_AREA } from '../utils/constants';
import { unpackStore } from '@/utils/pinia/unpackStore';
import { capDateRangeToSlider } from '@/utils/date';
import { getPreference, setPreference } from '@/utils/preferencesManager';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import { useRoute } from 'vue-router/composables';
export const useOpportunityAtlasFilterStore = defineStore('opportunity-atlas-filter-store', () => {
    const queryParamsInitializedFromPreferences = ref(false);
    const route = useRoute();
    // triggers list store - connected with search filters
    const triggersListStore = useTriggersListStore();
    const { triggerSearchParams } = storeToRefs(triggersListStore);
    const { state: exploreTriggersStoreState } = unpackStore(useExploreTriggersStore());
    const overlays = ref(['problem']);
    const showHeatmapChanges = ref(false);
    const [cappedStartDate, cappedEndDate] = capDateRangeToSlider(triggerSearchParams.value.dateRanges);
    const heatmapChangesDateRanges = ref([cappedStartDate.format('YYYY-MM-DD'), cappedEndDate.format('YYYY-MM-DD')]);
    // Inventive potential
    const shouldFilterInventiveness = computed(() => triggerSearchParams.value.inventivenessRanges[0] !== 0 ||
        triggerSearchParams.value.inventivenessRanges[1] !== INVENTIVENESS_SCORES.length - 1);
    const inventivnessScoreFrom = computed(() => shouldFilterInventiveness.value
        ? INVENTIVENESS_SCORES[triggerSearchParams.value.inventivenessRanges[0]]
        : undefined);
    const inventivnessScoreTo = computed(() => shouldFilterInventiveness.value
        ? INVENTIVENESS_SCORES[triggerSearchParams.value.inventivenessRanges[1]]
        : undefined);
    // Cluster size filter
    const clusterSize = ref([0, CLUSTER_SIZE_CHOICES.length - 1]);
    const shouldShowCLusterSizeFilterPill = computed(() => {
        return clusterSize.value[0] !== 0 || clusterSize.value[1] !== CLUSTER_SIZE_CHOICES.length - 1;
    });
    const removeClusterSizeFilter = () => {
        clusterSize.value = [0, CLUSTER_SIZE_CHOICES.length - 1];
    };
    // filters above the heatmap - problem/advance
    const featureTypes = ref([]);
    // heatmap zoom area
    const zoomArea = ref(cloneDeep(DEFAULT_ZOOM_AREA));
    // action next to the query - bm25
    const queryAction = ref(FeatureMiningQueryAction.BM25);
    const queryParams = computed(() => {
        const featureTypes = [];
        if (overlays.value.includes('ability'))
            featureTypes.push('ability');
        if (overlays.value.includes('problem'))
            featureTypes.push('problem');
        return {
            feature_types: featureTypes,
            query: triggerSearchParams.value.query,
            must_not_match: triggerSearchParams.value.must_not_match,
            types: triggerSearchParams.value.types,
            date_from: triggerSearchParams.value.dateRanges[0],
            date_to: triggerSearchParams.value.dateRanges[1],
            exists_filter: triggerSearchParams.value.includeProcessingDocuments ? [] : ['essential_features'],
            inventiveness_score_from: inventivnessScoreFrom.value,
            inventiveness_score_to: inventivnessScoreTo.value,
            assignees: triggerSearchParams.value.assignees,
            action: queryAction.value,
            scoping_aspects: triggerSearchParams.value.scoping_aspects,
            aspects_combination_type: triggerSearchParams.value.aspects_combination_type
        };
    });
    const isTrackedOverlayApplied = computed(() => {
        if (exploreTriggersStoreState.value.oppAtlasActiveView) {
            return overlays.value.includes('tracked');
        }
        return triggerSearchParams.value.typeFilter === 'TRACKED';
    });
    watch(() => triggerSearchParams.value.dateRanges, (newDateRanges) => {
        const [cappedStartDate, cappedEndDate] = capDateRangeToSlider(newDateRanges);
        heatmapChangesDateRanges.value = [cappedStartDate.format('YYYY-MM-DD'), cappedEndDate.format('YYYY-MM-DD')];
    });
    const setHeatmapChangesDateRanges = (dateRanges) => {
        heatmapChangesDateRanges.value = dateRanges;
    };
    // ONLY used in `opportunity atlas` as standalone view
    watch([queryParams, clusterSize], () => {
        // save in preferences only if Opportunity atlas standalone view -> not in project
        setOppAtlasQueryInPreferences();
    });
    const setOppAtlasQueryInPreferences = debounce(() => {
        if (!route.params.project_id && queryParamsInitializedFromPreferences.value) {
            setPreference('OPPORTUNITY_ATLAS_FILTERS', {
                ...queryParams.value,
                clusterSize: clusterSize.value,
                inventivenessRanges: triggerSearchParams.value.inventivenessRanges
            });
        }
    }, 500);
    // ONLY used in `opportunity atlas` as standalone view
    const getOppAtlasQueryParamsFromPreferences = () => {
        if (route.params.project_id)
            return;
        const preference = getPreference('OPPORTUNITY_ATLAS_FILTERS');
        overlays.value = preference?.feature_types ?? ['problem'];
        queryAction.value = preference?.action ?? FeatureMiningQueryAction.BM25;
        clusterSize.value = preference?.clusterSize ?? [0, CLUSTER_SIZE_CHOICES.length - 1];
        triggerSearchParams.value = {
            ...triggerSearchParams.value,
            query: preference?.query ?? [],
            must_not_match: preference?.must_not_match ?? [],
            types: preference?.types ?? [],
            dateRanges: preference?.date_from && preference?.date_to ? [preference?.date_from, preference?.date_to] : [],
            includeProcessingDocuments: preference?.exists_filter?.length === 0,
            inventivenessRanges: preference?.inventivenessRanges ?? triggerSearchParams.value.inventivenessRanges,
            assignees: preference?.assignees ?? [],
            scoping_aspects: preference?.scoping_aspects ?? [],
            aspects_combination_type: preference?.aspects_combination_type ?? 'OR'
        };
        queryParamsInitializedFromPreferences.value = true;
    };
    const state = ref({
        queryParams,
        overlays,
        featureTypes,
        zoomArea,
        clusterSize,
        shouldShowCLusterSizeFilterPill,
        showHeatmapChanges,
        heatmapChangesDateRanges,
        isTrackedOverlayApplied
    });
    const actions = reactive({
        removeClusterSizeFilter,
        setHeatmapChangesDateRanges,
        getOppAtlasQueryParamsFromPreferences
    });
    return { state, actions };
});
