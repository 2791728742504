import { createPinia, PiniaVuePlugin } from 'pinia';
import './plugins/gravatar';
import './plugins/http';
import './plugins/vuelidate';
import './plugins/asyncComputed';
import './plugins/splitPane';
import Vue from 'vue';
import VueRouter from 'vue-router';
import App from './App.vue';
import { i18n } from './plugins/i18n';
import { initRum } from './plugins/rum';
import vuetify from './plugins/vuetify';
import './directive/permission';
import './directive/leaveGuard';
import './modules/agent/directives/OnscreenVisibleAgentData';
import './directive/storePendingChanges';
import router from './router';
import store from './store';
import { setDayjsLocale } from './utils/i18n';
import './styles/global.scss';
setDayjsLocale();
const pinia = createPinia();
Vue.use(PiniaVuePlugin);
Vue.use(VueRouter);
Vue.config.productionTip = false;
const config = {
    router,
    store,
    pinia,
    vuetify,
    i18n,
    render: (h) => h(App)
};
new Vue((config)).$mount('#app');
initRum(router);
