// Register a global custom directive called `v-focus`
import { useAgentStore } from '@/modules/agent/store/useAgentStore';
import isFunction from 'lodash/isFunction';
import isString from 'lodash/isString';
import Vue from 'vue';
export const onscreenVisibleAgentDataDirective = Vue.directive('OnscreenVisibleAgentData', {
    inserted: function (el, binding) {
        const store = useAgentStore();
        const valueToProvide = () => {
            // @ts-ignore
            if (!el.checkVisibility())
                return undefined;
            if (!binding.value)
                return el.outerText;
            if (isFunction(binding.value))
                return binding.value();
            if (isString(binding.value))
                return binding.value;
            console.error('Unhandled value', binding.value);
        };
        // @ts-ignore
        el._uid = String(el.__vue__._uid);
        // @ts-ignore
        Vue.set(store.onscreenContent, el._uid, valueToProvide);
    },
    unbind: function (el) {
        // @ts-ignore
        Vue.delete(useAgentStore().onscreenContent, el._uid);
    }
});
